import React from "react"

function MediumBlog() {
  return (
    <div
      id="retainable-rss-embed"
      data-rss="https://medium.com/feed/retainable,
     https://medium.com/feed/js-dojo,                https://medium.com/feed/vue-mastery"
      data-maxcols="4"
      data-layout="slider"
      data-poststyle="modal"
      data-readmore="Read the rest"
      data-buttonclass="btn btn-primary"
      data-offset="-200"
    >
      &#160;
      <script src="https://www.retainable.io/assets/retainable/rss-embed/retainable-rss-embed.js"></script>
    </div>
  )
}

export default MediumBlog

// https://www.retainable.io/embed-your-medium-blog
// https://www.retainable.io/vue-news
