import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MediumBlog from "../components/MediumBlog/index"

function Blog() {
  return (
    <Layout>
      <SEO title="Blog" />
      <Link to="/">Home</Link>
      <MediumBlog />
      <h3>
        Blog available here:
        https://medium.com/@hannahmurphyk/bigquery-public-datasets-practical-application-in-real-world-circumstances-33b7a8a35766
      </h3>
      <p>
        Integration of medium blog coming soon upon the completion of portfolio
        site.
      </p>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  )
}
export default Blog
